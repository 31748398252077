import React from 'react';

// order of imports matters here - styles need to be imported BEFORE App.tsx is imported. As lib-ui-react-common comes
// with it's own set of styles, that are overwriting bootstrap styles, they need to be imported AFTER import of bootstrap css.
import '../../theme/custom-bootstrap-theme-light.scss';
import 'font-awesome/css/font-awesome.css';

import {OktaError} from './okta';
import {createRoot} from 'react-dom/client';

const rootElement: any = document.getElementById('root-okta-error');
if (rootElement) {
    const root = createRoot(rootElement);
    root.render(<OktaError />);
}
