import React from 'react';
import styles from '../Errors.module.scss';

export const OktaError = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.box}>
                <div className={`card bg-light ${styles.card}`}>
                    <div className="card-header text-danger">Error</div>
                    <div className="card-body">
                        <h4 className="card-title">We are sorry</h4>
                        <p className="card-text">
                            An application error occurred.
                            <br />
                            You are not assigned to this app in Okta.
                        </p>
                        <p>
                            If you're wondering why this is happening, please
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://accenture-acp-dev-admin.oktapreview.com/admin/dashboard/"
                            >
                                {' '}
                                contact your administrator.
                            </a>
                        </p>
                        <p>
                            If it's any consolation, we can take you to
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://accenture-acp-dev-admin.oktapreview.com/admin/dashboard/"
                            >
                                {' '}
                                your okta home page.
                            </a>
                        </p>
                    </div>
                    <footer className={styles.footer}>
                        <a className={styles.footerLink} href="https://cloudportal.accenture.com/public/terms-of-use/">
                            Terms of use
                        </a>
                        <br />
                        <small>©2020 Accenture All Rights Reserved</small>
                    </footer>
                </div>
            </div>
        </div>
    );
};
